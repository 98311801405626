import Container from 'react-bootstrap/Container';
import Row from  'react-bootstrap/Row';
import Col from  'react-bootstrap/Col';

import {BsHeartFill} from 'react-icons/bs';
import gopher from '../images/go.png';

const Footer = () => {
  return (
    <Container className='footer-container'>
      <Row className='justify-content-center'>
        <Col xs sm md='auto'>
          <footer>
            <h6>Made with <BsHeartFill color='#D2042D'/></h6>
            <a href='https://go.dev/blog/gopher' target='_blank' rel='noreferrer'><img src={gopher} alt="Golang gopher"/></a>
          </footer>
        </Col>
      </Row>
    </Container>
    
  );
};

export default Footer;
