import Container from 'react-bootstrap/Container';
import Row from  'react-bootstrap/Row';
import Col from  'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { HiLocationMarker } from 'react-icons/hi';
import { BsGithub, BsLinkedin } from 'react-icons/bs';

import persona from '../images/tobias.png'; 
import lastmile from '../images/lastmile.PNG';
import zeebe from '../images/zeebe.png';

const About = () => {
  return (
  <Container className='about-container'>
      <Row className='justify-content-center'>
          <Col xs md='auto'>
            <Image src={persona} alt='persona' responsive='true' roundedCircle />
          </Col>
      </Row>
      <Row className='justify-content-center'>
          <Col xs md='auto'>
            <h1>TOBIAS SCHIPHORST</h1>
          </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col xs md='auto'>
          <p>Student Technology & Operations Management | <a className='no-link-decoration' href='https://www.google.com/maps/place/Groningen' target='_blank' rel='noreferrer'><HiLocationMarker style={{verticalAlign: 'middle'}} size='1.2em'/> Groningen</a></p>
        </Col>
      </Row>
      <Row className='justify-content-center'> 
        <Col xs md='auto'>
          <p>
              Hello! I am a Dutch student finishing my master in Technology & Operations Management at the Rijksuniversiteit Groningen.
              I have a bachelor degree in software engineering, and mostly experienced in Python, JavaScript and Golang. 
          </p>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col xs md='auto'>
          <a className='no-link-decoration' href='https://github.com/teletobbie' target='_blank' rel='noreferrer'><BsGithub size='2em' /></a>  <a href='https://www.linkedin.com/in/tobias-schiphorst-078377a3/' target='_blank' rel='noreferrer'><BsLinkedin size='2em' /></a>
        </Col>
      </Row>
      <br />
      <Row className='justify-content-center'>
        <Col xs md='6'>
          <h2>Latest projects</h2>
          <p>I've collaborated and worked on different projects, these can be viewed below.</p>
        </Col>
      </Row>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card className='current-project-card'>
            <a href="https://tschiphorst.nl/lastmilegroningen.html" target='_blank' rel="noreferrer"><Card.Img variant="top" src={lastmile} /></a>
            <Card.Body>
              <Card.Title>Data visualization <Badge pill >Python</Badge></Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Developed in 2022</Card.Subtitle>
              <Card.Text>
                  For my reseach I've created an model that measured if the location of distribution centres have influence on the routelength within last mile cargo bike delivery. I created an small visualization of my results using Python.  
              </Card.Text>
              <Card.Link className="card-link" href='https://tschiphorst.nl/lastmilegroningen.html'>Demo Groningen</Card.Link>
              <Card.Link href='https://tschiphorst.nl/lastmileamsterdam.html'>Demo Amsterdam</Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='current-project-card'>
            <Card.Img variant="top" src={zeebe}/>
            <Card.Body>
              <Card.Title className="card-text-color">Zeebe infrastructure designer <Badge pill bg='info'>Golang</Badge> <Badge pill bg='warning'>JavaScript</Badge></Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Developed in 2021</Card.Subtitle>
              <Card.Text className="card-text-color">
                For my bachelor graduation internship at KPN Groningen I've developed the Zeebe Infrastructure Designer (ZID). Zeebe Infrastructure Designer is a web-design tool to create, edit and deploy deployment diagrams using the Zeebe-API. The backend was developed in Golang and the frontend in JavaScript.      
              </Card.Text>
              <Button className='card-button' href="https://github.com/teletobbie/zid" variant="primary">Code</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  </Container>
  );
};

export default About;
